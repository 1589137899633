import React from "react"
import { TabellaDocumenti } from "../components/tabella"
import { LayoutDocumenti } from "../components/layout"
import Seo from "../components/seo"

const DocumentiPage = () => (
    <LayoutDocumenti>
        <Seo title="Documenti" />
        <TabellaDocumenti />
    </LayoutDocumenti>
)

export default DocumentiPage
